import React from "react";
var remark = require("remark");
import remarkHTML from "remark-html";
import { HTMLContent } from "../components/Content";
import "./styles/Service.scss";

const toHTML = (value) => {
  let res;
  remark()
    .use(remarkHTML)
    .process(value, (err, file) => {
      console.log(String(file));
      res = String(file);
    });
  return res;
};

export default function Services({ className, service }) {
  return (
    <div
      className={`columns has-background-white box mb-5 is-shadowless service-container ${className}`}
    >
      <div className="column has-text-left">
        <div className="is-flex is-justify-content-space-between is-flex-wrap-wrap pb-2">
          <h4 className="is-size-5 mb-0 has-text-weight-semibold">
            {service.title}
          </h4>
          <p>{service.price}</p>
        </div>
        <p className="has-text-grey mb-0">{service.description}</p>
        {service?.extraDescription && (
          <HTMLContent content={toHTML(service.extraDescription)} />
        )}
      </div>
    </div>
  );
}
