import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import ServicesCategory from "../components/ServicesCategory";
import Social from "../components/Social";
import BookingButton from "../components/BookingButton";

export const ServicesPageTemplate = ({ services }) => {
  return (
    <div>
      <h1 style={{ display: "none" }}>
        Ms. Tang’s Nails and Spa Price List | Nail salon | Santa Maria, CA 93455
      </h1>
      <div className="background is-clipped">
        <section className="service-hero is-medium is-flex is-align-items-flex-end">
          <div className="gloss-effect" style={{ right: 15, bottom: 100 }}>
            <h1 className="has-text-centered">Price List</h1>
          </div>
        </section>
      </div>
      <section className="price-list">
        {services.map((category, index) => {
          return <ServicesCategory category={category} key={index} />;
        })}
      </section>
      <section className="social bg-alt section is-medium">
        <Social />
      </section>
      <div className="fab">
        <Link to="/booking/">
          <BookingButton />
        </Link>
      </div>
    </div>
  );
};

ServicesPageTemplate.propTypes = {
  services: PropTypes.array,
};

const ServicesPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <ServicesPageTemplate services={frontmatter.services} />
    </Layout>
  );
};

ServicesPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ServicesPage;

export const pageQuery = graphql`
  query ServicesPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "services-page" } }) {
      frontmatter {
        services {
          category
          service {
            title
            description
            extraDescription
            price
          }
        }
      }
    }
  }
`;
