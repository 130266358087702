import React from 'react'
import './styles/Social.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'

export default function Social(props) {
	return (
		<div className='social-wrapper has-text-centered'>
			<h1 className="title has-text-weight-bold">Follow Us</h1>
			<h2 className="subtitle mb-2">
				Stay in the loop and see our latest work on our Facebook and Instagram page.
			</h2>
			<div className="is-size-2">
				<a href="https://www.facebook.com/MsTangs-Nails-Spa-102048968589804" target="_blank" rel="noreferrer" aria-label="Facebook Page Link" className="mr-4">
					<FontAwesomeIcon icon={faFacebook}/>
				</a>
				<a href="https://www.instagram.com/ms.tangs_nails/" target="_blank" rel="noreferrer" aria-label="Instagram Page Link">
                    <FontAwesomeIcon icon={faInstagram}/>
                </a>
			</div>
		</div>
	)
}
