import React from "react";
import Services from "./Services";
import "./styles/ServicesCategory.scss";

export default function ServicesCategory({ category }) {
  return (
    <div className="container">
      <div className="box category tag-line columns is-desktop">
        <h1 className="is-size-3 has-text-weight-semibold mb-4 column is-3-desktop is-10-tablet">
          {category.category}
        </h1>
        <div className="column is-9-desktop is-12-tablet services-wrapper">
          {category.service.map((e, index) => (
            <Services service={e} key={e.title + index} />
          ))}
        </div>
      </div>
    </div>
  );
}
